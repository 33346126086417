import React, { useState, useEffect } from 'react';
import ParticleBackground from '../util-Components/ParticleBackground';
import ScrollFade from '../util-Components/ScrollFade';
import { Link } from 'react-router-dom'

import ContactForm from '../Users-Component/contact-form/ContactForm'

const Home = ({  section1Ref, section2Ref, section3Ref, section4Ref, section5Ref , content, scrollTarget  }) => {

  const [focusedIndex, setFocusedIndex] = useState(null); // Track focus index

  // State to manage which content is currently visible
  const [activeIndex, setActiveIndex] = useState(parseInt(localStorage.getItem('activeIndex')) || 0);

  // Array holding unique content for each collapse
  const contents = [
    {
      button: "Consultation",
      title: 'Consultation',
      text: 'We start by understanding your business goals, company culture, and specific hiring needs through in-depth consultations.'
    },
    {
      button: "Candidate Identification",
      title: 'Candidate Identification',
      text: 'Leveraging our extensive network and resources, we identify potential candidates who align with your requirements.'
    },
    {
      button: "Screening and Evaluation",
      title: 'Screening and Evaluation',
      text: 'Our rigorous screening process ensures that only the most qualified and culturally fit candidates are presented to you.'
    },
    {
      button: "Client-Candidate Match",
      title: 'Client-Candidate Match',
      text: 'We facilitate the interview and selection process, assisting in the seamless integration of the chosen candidate into your team..'
    }
  ];


  useEffect(() => {
    localStorage.setItem('activeIndex', activeIndex);
    if (scrollTarget && scrollTarget.current) {
      scrollTarget.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeIndex,scrollTarget]);

  // Function to toggle the collapse
  const handleToggle = (index) => {
    // If the same button is clicked again, collapse the content, else show new content
    setActiveIndex(activeIndex === index ? null : index);
    setActiveIndex(index);
  };



  return (

    <div className="container-fluid">
      < ParticleBackground />
      <div className="row">

        <div id="banner" className="container banner">
          
          <ScrollFade>
            <h3>Welcome to</h3>
            <h1>Shanvatech</h1>
            <p>
              We offer tailored IT services and consultancy solutions, prioritizing innovation, efficiency and
              client satisfaction. With expertise in developing custom software applications, optimizing systems
              and providing strategic guidance, we collaborate closely with clients to deliver exceptional results
              aligned with their business objectives. ShanvaTech is the trusted partner for organizations seeking
              innovative IT solutions and expert consultancy services, specialised in identifying and nurturing the
              right professional who will enhance the value of your business
            </p>
          </ScrollFade>
        </div>


        <div className="container our-commitment" ref={section1Ref} >

          <h2>Our Commitment</h2>
          <ScrollFade>
            <div className="row">

              <div className="col-md-6 col-lg-3">
                <div className="content-box">
                  <h3>Exceptional Talent Acquisition</h3>
                  <p>We understand that your company’s success depends on the caliber of your team. Our dedicated team of experts is committed to identifying and recruiting top-tier talent across various industries.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="content-box">
                  <h3>Customized Solutions</h3>
                  <p>
                    We recognize that every business is unique. Our approach is tailored to your specific needs,
                    ensuring that we find candidates who not only meet the skill requirements but also align with
                    your company culture.
                    </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="content-box">
                  <h3>Efficiency and Speed</h3>
                  <p>
                    Time is of the essence in the business world. We pride ourselves on our streamlined
                    and efficient hiring process, ensuring that you have the right professionals in place when you
                    need them.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="image1">
                  <img src={`${process.env.PUBLIC_URL}/images/hands.png`} alt="My Image" />
                </div>
              </div>

            </div>
          </ScrollFade>
        </div>

      </div>


      <div className="container" ref={section2Ref} >
        <h2>Why ShanvaTech</h2>
        <ScrollFade>
          <div className="row">

            <div className="col-md-6 col-lg-3">
              <div className="image">
                <img src={`${process.env.PUBLIC_URL}/images/man_thinking_1.png`} alt="My Image" />
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="content-box">
                <h3>Proven Track Record</h3>
                <p>With a successful history of talent acquisition, we have assisted numerous companies in building high-performing teams.</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="content-box">
                <h3>Client-Centric Approach</h3>
                <p>Your success is our priority. We work closely with you to understand your unique needs and challenges, ensuring a personalized hiring solution
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="content-box">
                <h3>Industry Expertise</h3>
                <p>Your success is our priority. We work closely with you to understand your unique needs and challenges, ensuring a personalized hiring solution.</p>
              </div>
            </div>

          </div>
        </ScrollFade>
      </div>

      <div className="container ourService" ref={section3Ref}>
        <h2>Our Services</h2>
        <ScrollFade>
          <div>
            <p>
              ShanvaTech is a leading provider of comprehensive staffing and IT services, dedicated to delivering tailored solutions to meet the diverse needs of our clients. With a commitment to excellence, innovation, and client satisfaction, we strive to be the partner of choice for businesses seeking top-notch talent and robust IT solutions.
            </p>
          </div>

          <div className="row no-gutters shanvatech-row">
            <div className="col shanvatech OurImage1">
              <div className="overlay-content">
                <h4>Staffing Services</h4>
                <p>Matching talent to needs: “We carefully match the right talent with your unique business needs, ensuring they have the skills and experience to make a significant impact from day one.”</p>
                <Link to="/staffing-service" ><button type="button" className="btn btn-primary OurService-knowMore">KNOW MORE</button></Link>

              </div>
            </div>

            <div className="col shanvatech OurImage2">
              <div className="overlay-content">
                <h4>IT Services</h4>
                <p>A strategic approach to consolidate and centralize IT functions across different departments/business units within an organization to achieve several key benefits</p>
                <Link to="/it-service" ><button type="button" className="btn btn-primary OurService-knowMore">KNOW MORE</button></Link>
              </div>
            </div>

            {/*     <div className="col shanvatech OurImage3">
              <h5>Content 3</h5>
              <p>This is content 3. Hover to expand.</p>
            </div>
            <div className="col shanvatech OurImage4">
              <h5>Content 4</h5>
              <p>This is content 4. Hover to expand.</p>
            </div>    */}
          </div>
        </ScrollFade>
      </div>


      <div className="container" ref={section4Ref}>
        <h2>How we work</h2>
        <ScrollFade>
          <div className="row">

            {/* Column for buttons */}
            <div className="col-md-6 contentCollapse" >
              {contents.map((content, index) => (
                <div key={index}>
                  <button
                    onClick={() => handleToggle(index)}
                    className='btn btn-primary btn-block custom-btn'
                  >
                    {content.button}
                  </button>
                </div>
              ))}
            </div>

            {/* Column for displaying content */}
            <div className="col-md-6 contentCollapse">
              {activeIndex !== null && (
                <div className="collapse show">
                  <div className="card card-body ">

                    <h5>{contents[activeIndex].title}</h5>
                    <p>{contents[activeIndex].text}</p>

                  </div>
                </div>

              )}
            </div>

          </div>
        </ScrollFade>
      </div >
      <div  ref={section5Ref}>
      <ContactForm content={content} />
      </div>

    </div>
  )
}

export default Home
