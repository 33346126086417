import React, { useEffect } from "react";
import "particles.js";  // Importing as a side effect


function ParticleBackground() {
  function adjustParticlesHeight() {
    const particles = document.getElementById('particles-js');
    const banner = document.getElementById('banner');
    const computedstyle = window.getComputedStyle(banner);
    const heightvalue = computedstyle.getPropertyValue('height');
    const numericheight = parseFloat(heightvalue);
    if(!particles || !banner){
      return;
    }
    particles.style.setProperty('height',`${numericheight+100}px`,"important");
  }
  window.addEventListener('load', adjustParticlesHeight);
  window.addEventListener('resize', adjustParticlesHeight);

  useEffect(() => {
    window.particlesJS("particles-js", {
      fps_limit: 0,  
      particles: {
        number: { value: 30 },
        color: { value: "#ffffff" },
        shape: { type: "circle" },
        opacity: { value: 0.5 },
        size: { value: 3 },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: { enable: true, speed: 3 },
      },
    });
  }, []);

  return <div id="particles-js"></div>;
}

export default ParticleBackground;
